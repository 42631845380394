import React, { useEffect, useState } from "react";
import { getPropertyApi } from "../../api";
import ReactPaginate from "react-paginate";
import toast from "toastr";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Loader from "../../components/Loader";
import { formatDate, currancyFormat } from "../../utils/index";
import NotificationFormModal from "../../components/Modals/notificationFormModal";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { notificationApi } from "../../api";

const settings = {
  dots: false,
  infinite: false,
  speed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  arrows: false,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ],
};

const PropertyPage = () => {
  toast.options = { preventDuplicates: true };
  const navigate = useNavigate();
  const [properties, setProperties] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1); // Current page index
  const itemsPerPage = 12; // Number of items per page
  const [loader, setLoader] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [propertyDetails, setPropertyDetails] = useState({});

  useEffect(() => {
    fetchProperties(1);
  }, []);

  const fetchProperties = async (pageNumber) => {
    try {
      setLoader(true);
      const response = await getPropertyApi({ page: pageNumber }); // API pagination usually starts from 1
      if (response?.status == 200) {
        setLoader(false);
        setProperties(response.data?.data); // Set fetched properties
        setTotalCount(response.data?.count); // Set total number of pages
      } else if (response?.data?.error_description?.statusCode == 401) {
        toast.error(response?.data?.error_description?.message);
        setLoader(false);
        localStorage.clear();
        navigate("/login");
      } else {
        setLoader(false);
        console.error("Failed to fetch data:", response);
      }
    } catch (error) {
      setLoader(false);
      console.error("Error fetching data:", error);
    }
  };

  const handlePageClick = (selectedPage) => {
    fetchProperties(selectedPage.selected + 1);
    setCurrentPage(selectedPage.selected + 1);
  };

  const pageCount = Math.ceil(totalCount / itemsPerPage);

  const handleNotificationSubmit = async (property) => {
    let localUserData = localStorage.getItem("userInfo");
    if (localUserData) {
      localUserData = JSON.parse(localUserData);
    }

    setLoader(true);

    try {
      const response = await notificationApi({
        name: localUserData.userName,
        email: localUserData.email,
        phone: localUserData.mobile,
        propertyId: property?.id,
      });
      if (response?.status === 200) {
        toast.success(response.data?.message);
      } else {
        toast.error("Failed to subscribe notification!");
      }
    } catch (error) {
      toast.error("Something want wrong!");
    } finally {
      setLoader(false);
    }
  };

  return (
    <section>
      <p className="text-3xl text-primary font-bold text-center w-full mb-4">
        Properties
      </p>
      <div className="container">
        <div className="row">
          {!loader && properties && properties.length > 0 ? (
            properties.map((val) => (
              <div
                key={val.id}
                className="col-xs-12 col-sm-6 col-md-6 col-lg-4 mb-4"
              >
                <a href={`/property-details?id=${val?.id}`} className="img">
                  <div className="features-1 rounded-lg p-6">
                    <Slider {...settings}>
                      {val?.images.map((val1) => {
                        return (
                          <div className="relative">
                            {/* Image Section */}
                            <img
                              src={val1}
                              alt="Image"
                              className="h-[240px] object-cover w-full rounded-lg"
                            />
                          </div>
                        );
                      })}
                    </Slider>

                    <div className="text-start">
                      <p className="property-title mt-4">
                        {val.name}
                        {new Date(val.launchingDate) > new Date() && (
                          <FontAwesomeIcon
                            icon={faBell}
                            className="w-6 h-6 cursor-pointer text-[#EA9832] float-right"
                            onClick={(e) => {
                              e.preventDefault();
                              if (localStorage.getItem("userInfo")) {
                                handleNotificationSubmit(val);
                              } else {
                                setPropertyDetails(val);
                                setModalOpen(true);
                              }
                            }}
                          />
                        )}
                      </p>
                      <div className="row">
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                          <span>Each Bric Cost</span>
                          <p className="text-primary font-bold">
                            {currancyFormat(val.eachBricCost)}
                          </p>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                          <span>Launch Date</span>
                          <p className="text-primary font-bold">
                            {formatDate(val.launchingDate)}
                          </p>
                        </div>

                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                          <span>Status</span>
                          <p className="status-badge">
                            {val.availableBrics === 0 ? (
                              <span className="badge bg-danger">Sold Out</span>
                            ) : new Date(val.launchingDate) > new Date() ? (
                              <span className="badge bg-warning">
                                Coming Soon
                              </span>
                            ) : (
                              <span className="badge bg-success">
                                Available
                              </span>
                            )}
                          </p>
                        </div>

                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                          <span>Available</span>
                          <p className="text-primary font-bold">
                            {val.availableBrics}
                          </p>
                        </div>

                        {val.location ? (
                          <p>
                            {val.location.length > 50
                              ? val.location.substring(0, 50) + "..."
                              : val.location}
                          </p>
                        ):(<p>-</p>)}

                        <div className="property-status col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-4">
                          {/* Progress Bar Section */}
                          <div className="col-12">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                              <span className="text-primary font-bold">
                                Brics Sold
                              </span>
                              <span className="text-sm font-medium text-gray-600">
                                {val.totalBrics - val.availableBrics}/
                                {val.totalBrics} Brics
                              </span>
                            </div>

                            <div className="w-full bg-gray-200 rounded-full h-3 relative">
                              <div
                                className="bg-green-500 h-3 rounded-full transition-all ease-out duration-300"
                                style={{
                                  width: `${
                                    ((val.totalBrics - val.availableBrics) /
                                      val.totalBrics) *
                                    100
                                  }%`,
                                }}
                              />
                              <div className="absolute inset-0 flex justify-center items-center text-gray-500 font-bold">
                                {Math.round(
                                  ((val.totalBrics - val.availableBrics) /
                                    val.totalBrics) *
                                    100
                                )}
                                %
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <a
                      href={`/property-details?id=${val?.id}`}
                      className="learn-more"
                    >
                      See details
                    </a>
                  </div>
                </a>
              </div>
            ))
          ) : loader ? (
            <Loader />
          ) : (
            <div className="col-12 text-center">No properties found.</div>
          )}
        </div>
        <ReactPaginate
          breakLabel="..."
          breakClassName="break-me"
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          className={"px-2 flex justify-center mt-6 font-bold"}
          pageClassName={
            "ml-2 mr-2  px-2.5 py-1 text-sm rounded-full font-bold"
          }
          onPageChange={handlePageClick}
          containerClassName="pagination"
          activeClassName="active bg-[#005555] text-white px-2.5 py-1 rounded-full"
          previousClassName="hidden"
          nextClassName="hidden"
        />
        {/* Modal */}
        <NotificationFormModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          propertyDetails={propertyDetails}
        />
      </div>
    </section>
  );
};

export default PropertyPage;
