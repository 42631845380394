import React, { useState } from "react";
import toast from "toastr";
import { notificationApi } from "../../api";

const NotificationFormModal = ({ modalOpen, setModalOpen, propertyDetails }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [loader, setLoader] = useState(false);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleNotificationSubmit = async () => {
    if (!formData.name || !formData.email) {
      toast.error("Please fill all fields!");
      return;
    }

    setLoader(true);

    try {
            const response = await notificationApi(
              {
                name: formData.name,
                email: formData.email,
                phone: formData.phone,
                propertyId: propertyDetails?.id,
              },
              propertyDetails?.id
            );
      if (response?.status === 200) {
        toast.success(response.data?.message);
        setModalOpen(false);
      } else {
        toast.error("Failed to subscribe notification!");
      }
    } catch (error) {
      toast.error("Something want wrong!");
    } finally {
      setLoader(false);
    }
  };

  return (
    modalOpen && (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        {loader && <div>Loading...</div>}
        <div className="bg-white rounded-lg p-6 w-full max-w-md relative">
          <button
            onClick={() => setModalOpen(false)}
            className="absolute top-2 right-2 font-bold text-[20px] text-gray-500 hover:text-gray-700"
          >
            &times;
          </button>
          <h2 className="text-xl font-bold mb-4 text-center">
            Subscribe for Notification
          </h2>

          <div className="flex flex-col mb-4">
            <label className="block font-bold mb-2" htmlFor="name">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className="w-full mt-1 border rounded-lg px-3 py-2"
              required
              placeholder="Enter your name"
            />
          </div>

          <div className="flex flex-col mb-4">
            <label className="block font-bold mb-2" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className="w-full mt-1 border rounded-lg px-3 py-2"
              required
              placeholder="Enter your email"
            />
          </div>

          <div className="flex flex-col mb-4">
            <label className="block font-bold mb-2" htmlFor="phone">
              Phone
            </label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              className="w-full mt-1 border rounded-lg px-3 py-2"
              placeholder="Enter your phone number"
            />
          </div>

          <div className="flex justify-end">
            <button
              disabled={loader}
              onClick={() => setModalOpen(false)}
              className="bg-gray-500 text-white px-4 py-2 rounded-lg mr-2"
            >
              Cancel
            </button>
            <button
              type="button"
              disabled={loader}
              onClick={handleNotificationSubmit}
              className="bg-green-500 text-white px-4 py-2 rounded-lg"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default NotificationFormModal;
