import React, { useEffect, useState } from "react";
import {
  withdrawRentApi,
  getTotalEarningApi,
  getCustomerBank,
  getRentWithdrawHistory,
} from "../../api";
import ReactPaginate from "react-paginate";
import toast from "toastr";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import { formatDate, currancyFormat } from "../../utils/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const WithdrawPage = () => {
  toast.options = { preventDuplicates: true };
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("withdrawRent");
  const [transactions, setTransactions] = useState([]);
  const [earningsData, setEarningsData] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [totalAccountsCount, setTotalAccountsCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [loader, setLoader] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [isRentModalOpen, setRentModalOpen] = useState(false);
  const [bankAccounts, setBankAccounts] = useState([]);
  // const [selectedAccount, setSelectedAccount] = useState('');

  useEffect(() => {
    fetchTotalEarnings();
    loadTransactions();
    fetchBankAccounts();
  }, [activeTab]);


  const fetchBankAccounts = async () => {
    try {
      setLoader(true);
      const res = await getCustomerBank();
      if (res?.status == 200) {
        setTotalAccountsCount(res.data.length);
        const verifiedAccount = res?.data.filter(e => e.verify)
        setBankAccounts(verifiedAccount);
        setLoader(false);
      } else if (res?.data?.error_description?.statusCode === 401) {
        toast.error(res?.data?.error_description?.message);
        setLoader(false);
      } else {
        setLoader(false);
        console.error("Failed to fetch data:", res);
      }
    } catch (error) {
      setLoader(false);
      console.error("Error fetching data:", error);
    }
  };

  const fetchTotalEarnings = async () => {
    try {
      setLoader(true);
      const response = await getTotalEarningApi();
      if (response?.status === 200) {
        setLoader(false);
        setEarningsData(response.data?.data);
      } else {
        handleApiError(response);
      }
    } catch (error) {
      setLoader(false);
      console.error("Error fetching data:", error);
    }
  };

  const loadTransactions = async (pageNumber) => {
    try {
      setLoader(true);
      const response = await getRentWithdrawHistory({ page: pageNumber });
      if (response?.status === 200) {
        setLoader(false);
        setTransactions(response.data?.data);
        setTotalCount(response.data?.count);

      } else if (response?.data?.error_description?.statusCode === 401) {
        toast.error(response?.data?.error_description?.message);
        setLoader(false);
        localStorage.clear();
        navigate("/login");
      } else {
        setLoader(false);
        console.error("Failed to fetch data:", response);
      }
    } catch (error) {
      setLoader(false);
      console.error("Error fetching data:", error);
    }
  };

  const handleApiError = (response) => {
    setLoader(false);
    if (response?.data?.error_description?.statusCode === 401) {
      toast.error(response?.data?.error_description?.message);
      localStorage.clear();
      navigate("/login");
    } else {
      console.error("Failed to fetch data:", response);
    }
  };

  const handlePageClick = (selectedPage) => {
    const page = selectedPage.selected + 1;
    loadTransactions(page);
    setCurrentPage(page);
  };

  const handleRentWithdraw = async () => {
    if (
      !dialogData.bank_name ||
      !dialogData.amount ||
      Number(dialogData.amount) === 0 ||
      Number(dialogData.amount) > Number(dialogData.currentBalance)
    ) {
      if (!dialogData.bank_name) {
        toast.error("Account number is required.");
      } else {
        toast.error("Invalid withdraw amount.");
      }
      return;
    }

    try {
      const payload = {
        amount: dialogData.amount,
        bank_account_no: dialogData.bank_account_no,
        bank_name: dialogData.bank_name,
        bank_ifsc: dialogData.bank_ifsc,
      };
      setLoader(true);
      const response = await withdrawRentApi(payload);
      if (response?.status === 200) {
        toast.success(response.data.message);
        setRentModalOpen(false);
        fetchTotalEarnings();
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Withdrawal error:", error);
    }
  };


  const pageCount = Math.ceil(totalCount / itemsPerPage);


  return (
    <div className="section-properties container">
      <div className="w-full flex justify-between items-center">
        <p
          className="font-bold text-primary text-xl cursor-pointer my-4"
          onClick={() => navigate(-1)}
        >
          <FontAwesomeIcon icon={faArrowLeft} /> Withdraw Details
        </p>
      </div>
      <div className="row mb-8">
        <div className="col-4">
          <div className="bg-[#f5f5f5] text-left rounded-lg p-6 mr-6">
            <span className="font-medium text-gray-700">
              Total Earning
            </span>
            <p className="font-semibold text-[#005555]">
              {currancyFormat(earningsData?.totalAmount)}
            </p>
            <span className="font-medium text-gray-700">
              Current Balance
            </span>
            <p className="font-semibold text-[#005555]">
              {currancyFormat(earningsData?.currentBalance)}
            </p>
            <span className="font-medium text-gray-700">
              Withdraw Request
            </span>
            <p className="font-semibold text-[#005555]">
              {currancyFormat(earningsData?.withdrawAmount)}
            </p>
            <button
              type="button"
              onClick={(e) => {
                setDialogData({ ...earningsData, amount: "" });
                setRentModalOpen(true);
              }}
              className="cursor-pointer border-2 border-[#005555] outlined px-2 py-1 text-[#005555] text-sm rounded-md"
            >
              Withdraw
            </button>
          </div>
        </div>

        <div className="col-8">
          <div className="tabs mb-4 d-flex">
            <button
              className={`tab-button-solo ${activeTab === "withdrawRent" ? "active-tab-border" : ""}`}
              onClick={() => setActiveTab("withdrawRent")}
            >
              Transactions
            </button>

          </div>
          {loader ? (
            <Loader />
          ) : (
            <div className="row">
              <div className="col-12">
                {
                  transactions && transactions.length > 0 ? (
                    <table className="table table-striped table-hover">
                      <thead>
                        <tr>
                          <th>Ref ID</th>
                          <th>Bank Account</th>
                          <th>Amount</th>
                          <th>Status</th>
                          <th>Reason</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactions.map((val) => (
                          <tr key={val.id}>
                            <td>{val.id}</td>
                            <td>{val.bankAccount}</td>
                            <td>{currancyFormat(val.amount)}</td>
                            <td>{val.status}</td>
                            <td>{val.reason || "-"}</td>
                            <td>{formatDate(val?.createdAt)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="col-12 text-center">No rent data found.</div>
                  )}

                <ReactPaginate
                  breakLabel="..."
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  className={"pagination justify-content-center pt-4"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  activeClassName={"active"}
                  onPageChange={handlePageClick}
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  previousClassName={"page-item"}
                  nextClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextLinkClassName={"page-link"}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {isRentModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          {loader && <div>Loading...</div>}
          <div className="bg-white rounded-lg p-6 w-full max-w-md relative">
            <button
              onClick={() => setRentModalOpen(false)}
              className="absolute top-2 right-2 font-bold text-[20px] text-gray-500 hover:text-gray-700"
            >
              &times;
            </button>
            <h2 className="text-xl font-bold mb-4 text-center">
              Withdraw Rent
            </h2>
            {bankAccounts.length == 0 && (
              <div
                id="alert-4"
                className="flex items-center p-4 mb-4 text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300"
                role="alert"
              >
                <svg
                  className="flex-shrink-0 w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                </svg>
                <span className="sr-only">Info</span>
                <div className="ml-3 text-sm font-medium">
                  {totalAccountsCount == 0 ? 'To withdraw the amount kindly add the Bank account information in the profile.' : 'Verification of your bank account is under review. You can withdraw once your account is verified'}
                </div>

              </div>
            )}
            <p className="flex flex-col mb-4">
              <span className="font-bold">
                Available Amount: {currancyFormat(dialogData.currentBalance)}
              </span>
              {/* <span className="w-full mt-1 bg-gray-200 border rounded-lg px-3 py-2">
              {totalBrics}
            </span> */}
            </p>

            <div className="flex flex-col mb-4">
              <label className="block font-bold mb-2" htmlFor="account">
                Account Number
              </label>
              <select
                id="bank_account_no"
                // value={
                //   dialogData.bank_account_no
                //     ? JSON.stringify({
                //         bank_account_no: dialogData.bank_account_no,
                //         bank_name: dialogData.bank_name,
                //         bank_ifsc: dialogData.bank_ifsc,
                //       })
                //     : ""
                // }
                onChange={(e) => {
                  const selectedAccount = JSON.parse(e.target.value);
                  setDialogData({
                    ...dialogData,
                    bank_account_no: selectedAccount.bank_account_no,
                    bank_name: selectedAccount.bank_name,
                    bank_ifsc: selectedAccount.bank_ifsc,
                  });
                }}
                className="w-full mt-1 border rounded-lg px-3 py-2"
              >
                <option value="">Select Account Number</option>
                {bankAccounts.map((account) => (
                  <option
                    key={account.id}
                    value={JSON.stringify({
                      bank_account_no: account.bankNumber,
                      bank_name: account.bankName,
                      bank_ifsc: account.bankIfsc,
                    })}
                  >
                    {account.bankNumber}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex flex-col mb-4">
              <label className="block font-bold mb-2" htmlFor="amount">
                Withdraw Amount
              </label>
              <input
                type="number"
                id="amount"
                value={dialogData.amount}
                placeholder="Enter amount"
                required
                onChange={(e) =>
                  setDialogData({ ...dialogData, amount: e.target.value })
                }
                className="w-full mt-1 border rounded-lg px-3 py-2"
                min="1"
                max={dialogData.totalAmount}
              />
            </div>

            <div className="flex justify-end">
              <button
                disabled={loader}
                onClick={() => setRentModalOpen(false)}
                className="bg-gray-500 text-white px-4 py-2 rounded-lg mr-2"
              >
                Cancel
              </button>
              <button
                type="button"
                disabled={loader}
                onClick={handleRentWithdraw}
                className="bg-green-500 text-white px-4 py-2 rounded-lg"
              >
                {loader && (
                  <svg
                    aria-hidden="true"
                    role="status"
                    class="inline mr-3 w-4 h-4 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    ></path>
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                )}
                Withdraw
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WithdrawPage;
