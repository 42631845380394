import React, { useEffect, useRef, useState } from "react";
import toast from "toastr";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import {
  getUserNominee,
  addNomineApi,
  deleteNomineeByID,
  handleEditNomineeByID,
} from "../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faTrash,
  faEdit,
  faTimes,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import AddNomineModal from "../../components/Modals/addNomineModal";

const NomineePage = () => {
  toast.options = { preventDuplicates: true };
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const [nomineeList, setNomineeList] = useState([]);
  const [showNominee, setShowNominee] = useState(false);
  const fileInputRef = useRef(null);
  const fileInputBackRef = useRef(null);
  const [nomineeDetail, setNomineeDetail] = useState({});
  const [imageUrl, setImageUrl] = useState(null);
  const [imageUrlBack, setImageUrlBack] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [imagePreviewBack, setImagePreviewBack] = useState(null);
  const [errorNominee, setErrorsNominee] = useState({});
  const [totalPercentage, setTotalPercentage] = useState(0);
  useEffect(() => {
    fetchNominee();
  }, []);

  const fetchNominee = async () => {
    try {
      setLoader(true);
      const res = await getUserNominee();
      if (res?.status == 200) {
        console.log("[22] =>", res?.data);
        setLoader(false);
        setNomineeList(res?.data);
        const total = res?.data.reduce((sum, item) => sum + item.percent, 0);
        setTotalPercentage(total);
      } else if (res?.data?.error_description?.statusCode === 401) {
        toast.error(res?.data?.error_description?.message);
        setLoader(false);
      } else {
        setLoader(false);
        console.error("Failed to fetch data:", res);
      }
    } catch (error) {
      setLoader(false);
      console.error("Error fetching data:", error);
    }
  };

  const handleChangeNominee = (e) => {
    setNomineeDetail({ ...nomineeDetail, [e.target.name]: e.target.value });
    setErrors({});
  };

  const handleNomineeClose = () => {
    setShowNominee(false);
    setNomineeDetail({});
  };

  const handleFileClick = () => {
    fileInputRef.current.click();
  };

  const handleFileBClick = () => {
    fileInputBackRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const validImageTypes = /\.(jpg|jpeg|png)$/i;

    if (!validImageTypes.test(file.name)) {
      toast.error("Please select a valid image file (jpg, jpeg, png)");
      fileInputRef.current.value = ""; // Clear the input
    } else {
      setImageUrl(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleABackChange = (event) => {
    const file = event.target.files[0];
    const validImageTypes = /\.(jpg|jpeg|png)$/i;

    if (!validImageTypes.test(file.name)) {
      toast.error("Please select a valid image file (jpg, jpeg, png)");
      fileInputBackRef.current.value = ""; // Clear the input
    } else {
      setImageUrlBack(file);
      setImagePreviewBack(URL.createObjectURL(file));
    }
  };

  const removeImage = () => {
    setImagePreview(null);
    setImageUrl(null);
    if (fileInputRef && fileInputRef?.value) {
      fileInputRef.current.value = ""; // Clear the input
    }
  };

  const removeBImage = () => {
    setImagePreviewBack(null);
    setImageUrlBack(null);
    if (fileInputBackRef && fileInputBackRef?.value) {
      fileInputBackRef.current.value = ""; // Clear the input
    }
  };

  const validate = () => {
    const percentPattern = /^(100|[1-9]?[0-9])$/; // Matches numbers from 0 to 100
    const numPattern = /^\d+$/; // Matches numbers only
    let newErrors = {};
    if (!nomineeDetail.name) {
      newErrors.name = "Name is required";
    } else if (!nomineeDetail.relation) {
      newErrors.relation = "Relation is required";
    } else if (!nomineeDetail.percent) {
      newErrors.percent = "Valid percent is required";
    } else if (!percentPattern.test(nomineeDetail.percent)) {
      newErrors.percent = "Percent must be a number between 0 and 100";
    } else if (!nomineeDetail.aadharcardNo) {
      newErrors.aadharcardNo = "Valid Aadhar Card No. is required";
    } else if (nomineeDetail.aadharcardNo.length != 12) {
      newErrors.aadharcardNo = " Aadhar Card Should have 12 digits no. only";
    } else if (!numPattern.test(nomineeDetail.aadharcardNo)) {
      newErrors.aadharcardNo = " Aadhar Card Should have 12 digits no. only";
    }

    setErrorsNominee(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAddNominee = async (e) => {
    e.preventDefault();
    try {
      if (validate()) {
        const nomineePercentage = parseInt(nomineeDetail.percent || 0);
        if (nomineePercentage <= 0)
          return toast.error("Percentage must be greater than 0%");
  
        // Exclude rejected nominees from percentage calculation
        const validNominees = nomineeList.filter(item => item.status !== "rejected");
        
        let newTotalPercentage =
          validNominees.reduce((sum, item) => sum + item.percent, 0) +
          nomineePercentage;
        let findRecord = {};
        if (nomineeDetail.id) {
          findRecord = validNominees.find((e) => e.id === nomineeDetail.id);
          newTotalPercentage -= findRecord?.percent || 0;
        }
  
        if (newTotalPercentage > 100) {
          let allowedPercentage =
            100 - validNominees.reduce((sum, item) => sum + item.percent, 0);
          if (nomineeDetail.id && findRecord) {
            allowedPercentage += findRecord.percent;
          }
          return toast.error(
            `Total percentage cannot exceed 100%. You can still allocate ${allowedPercentage}%.`
          );
        }
  
        if (nomineeDetail.Id) { 
          if (!imagePreview) {
            toast.error("Aadhar Card Front Image is required");
            return;
          }
          if (!imagePreviewBack) {
            toast.error("Aadhar Card Back Image is required");
            return;
          }
        }        
        setLoader(true);
  
        let res;
        if (nomineeDetail.id) {
          // Update nominee
          res = await handleEditNomineeByID(nomineeDetail.id, nomineeDetail);
        } else {
          // Add nominee
          res = await addNomineApi(nomineeDetail, imageUrl, imageUrlBack);
        }
  
        if (res?.status === 200) {
          toast.success(res?.data?.message);
          setNomineeDetail({});
          setShowNominee(false);
          setLoader(false);
          fetchNominee();
          removeImage();
          removeBImage();
          setTotalPercentage(newTotalPercentage);
        } else if (res?.data?.error_description?.statusCode == 401) {
          setNomineeDetail({});
          setLoader(false);
          toast.error(res?.data?.error_description?.message);
          localStorage.clear();
          navigate("/login");
        } else {
          setNomineeDetail({});
          setLoader(false);
          toast.error(res?.data?.error_description?.message);
        }
      }
    } catch (err) {
      setLoader(false);
      console.log("[218] =>", err);
      toast.error("Something went wrong. Please try again.");
    }
  };
  
  const deletNominee = async (id) => {
    try {
      setLoader(true);
      const res = await deleteNomineeByID(id);
      if (res?.status == 200) {
        setLoader(false);
        fetchNominee();
      } else if (res?.data?.error_description?.statusCode == 401) {
        toast.error(res?.data?.error_description?.message);
        setLoader(false);
      } else {
        setLoader(false);
        console.error("Failed to fetch data:", res);
      }
    } catch (error) {
      setLoader(false);
      console.error("Error fetching data:", error);
    }
  };

  const handleEditNominee = async (id) => {
    const nominee = nomineeList.find((item) => item.id === id);
    if (nominee?.verify) {
      setNomineeDetail({
        id: nominee.id,
        percent: nominee.percent,
        name: nominee.name,
        relation: nominee.relation,
        aadharcardNo: nominee.aadharcardNo,
        aadharcard: nominee.aadharcard,
        aadharcardBack: nominee.aadharcardBack,
        verify: nominee.verify,
      });
    } else {
      setNomineeDetail(nominee);
    }
    setShowNominee(true);
  };

  return (
    <>
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Top Navigation Section */}
        <div className="w-full flex justify-between items-center mb-6">
          <p
            className="font-bold text-primary text-xl mb-4 cursor-pointer "
            onClick={() => navigate(-1)}
          >
            <FontAwesomeIcon icon={faArrowLeft} /> Nominee Details
          </p>
          <button
            className="text-white bg-[#005555] px-4 py-2 rounded"
            onClick={(e) => {
              setShowNominee(true);
            }}
          >
            Add Nominee
          </button>
        </div>

        {loader ? (
          <Loader />
        ) : (
          <>
            {totalPercentage < 100 && (
              <div
                id="alert-4"
                className="flex items-center p-4 mb-4 text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300"
                role="alert"
              >
                <svg
                  className="flex-shrink-0 w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                </svg>
                <span className="sr-only">Info</span>
                <div className="ms-3 text-sm font-medium">
                  If you do not add any nominees, or if the total percentage of
                  shares across all nominees is less than 100%, your profile
                  will remain incomplete.
                </div>
                {/* <button
                  type="button"
                  className="ms-auto -mx-1.5 -my-1.5 bg-yellow-50 text-yellow-500 rounded-lg focus:ring-2 focus:ring-yellow-400 p-1.5 hover:bg-yellow-200 inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 dark:text-yellow-300 dark:hover:bg-gray-700"
                  data-dismiss-target="#alert-4"
                  aria-label="Close"
                >
                  <span className="sr-only">Close</span>
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                </button> */}
              </div>
            )}
            {!nomineeList.length && (
              <div className="col-12 text-center">No nominee found.</div>
            )}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-full">
              {nomineeList.map((item, index) => (
                <div
                  key={index}
                  className="relative border-2 border-dashed border-[#005555] text-[#005555] rounded-lg p-4 shadow-md transform transition-transform duration-300 hover:bg-[#F1F3F8]"
                >
                  {/* Trash Icon - Visible on hover */}
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="absolute top-2 right-2 text-gray-500 hover:text-red-600 cursor-pointer"
                    onClick={() => deletNominee(item.id)} // Handle delete
                  />
                  {item.status === "verified" && (
                    <FontAwesomeIcon
                      icon={faEdit}
                      className="absolute top-2 right-8 text-blue-500 hover:text-blue-700 cursor-pointer"
                      onClick={() => handleEditNominee(item.id)} // Handle edit
                    />
                  )}
                  <h2 className="text-xl font-semibold mb-2">{item.name}</h2>
                  <p>
                    <strong>Relation:</strong> {item.relation}
                  </p>
                  <p>
                    <strong>Percentage:</strong> {item.percent}%
                  </p>
                  <p>
                    <strong>Aadhar No:</strong> {item.aadharcardNo}
                  </p>
                  <p>
                    <strong>Created At:</strong>{" "}
                    {new Date(item.createdAt).toLocaleDateString()}
                  </p>

                  {/* Verification Status Chip */}
                  <div
                    className={`mt-2 inline-block px-2 py-1 text-sm font-semibold text-capitalize rounded ${
                      item.verify
                        ? "bg-green-100 text-green-700"
                        : "bg-red-100 text-red-700"
                    }`}
                  >
                    {item.status}
                  </div>
                  {item.reason && item.status === "rejected" && (
                    <p>
                      <strong>Reason:</strong> {item.reason}
                    </p>
                  )}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      <AddNomineModal show={showNominee} onClose={handleNomineeClose}>
        {loader ? <Loader /> : ""}
        <p className="w-full text-center text-xl text-[#005555] font-bold">
          {nomineeDetail.id ? "Edit Nominee" : "Add Your Nominee"}
        </p>
        <form className="w-full h-full px-2 py-8" onSubmit={handleAddNominee}>
          <div className="flex flex-col text-md mb-2">
            <label className="text-black text-sm mb-2">Name</label>
            <input
              type="text"
              name="name"
              value={nomineeDetail.name}
              onChange={handleChangeNominee}
              className="w-full text-sm px-2 py-2 bg-gray-200 rounded-md"
              placeholder="Name"
              disabled={nomineeDetail?.verify === true}
            />
            {errorNominee.name && (
              <p className="text-red-500 text-sm mt-1">{errorNominee.name}</p>
            )}
          </div>
          <div className="flex flex-row justify-between">
            <div className="w-full flex flex-col text-md mb-2">
              <label className="text-black text-sm mb-2">Relation</label>
              <select
                type="text"
                name="relation"
                onChange={handleChangeNominee}
                value={nomineeDetail.relation}
                className="w-full text-sm px-2 py-2 bg-gray-200 rounded-md"
                placeholder="Relation"
                disabled={nomineeDetail?.verify === true}
              >
                <option value="" selected disabled>
                  Select
                </option>
                <option value="Spouse">Spouse</option>
                <option value="Mother">Mother</option>
                <option value="Father">Father</option>
                <option value="Brother">Brother</option>
                <option value="Sister">Sister</option>
                <option value="Child">Child</option>
              </select>
              {errorNominee.relation && (
                <p className="text-red-500 text-sm mt-1">
                  {errorNominee.relation}
                </p>
              )}
            </div>

            <div className="w-full ml-2 flex flex-col text-md mb-2">
              <label className="text-black text-sm mb-2">Percent</label>
              <input
                type="text"
                name="percent"
                onChange={handleChangeNominee}
                value={nomineeDetail.percent}
                className="w-full text-sm px-2 py-2 bg-gray-200 rounded-md"
                placeholder="Percent"
                max={100}
                maxLength={3}
              />
              {errorNominee.percent && (
                <p className="text-red-500 text-sm mt-1">
                  {errorNominee.percent}
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-col text-md mb-2">
            <label className="text-black text-sm mb-2">Aadhar Card No.</label>
            <input
              type="text"
              name="aadharcardNo"
              onChange={handleChangeNominee}
              value={nomineeDetail?.aadharcardNo}
              maxLength={12}
              className="w-full text-sm px-2 py-2 bg-gray-200 rounded-md"
              placeholder="**** **** ****"
              disabled={nomineeDetail?.verify === true}
            />
            {errorNominee.aadharcardNo && (
              <p className="text-red-500 text-sm mt-1">
                {errorNominee.aadharcardNo}
              </p>
            )}
          </div>
          {!nomineeDetail.id && (
            <>
              <label className="text-black text-sm mb-2">
                Upload Nominee Aadhar Card Image
              </label>
              <div className="flex w-full">
                <div className="w-full flex flex-col text-md mb-2">
                  <div
                    className="w-full h-20 border-2 border-dashed border-gray-300 rounded-md flex flex-col items-center justify-center cursor-pointer"
                    onClick={() => {
                      if (imagePreview) {
                      } else {
                        handleFileClick();
                      }
                    }}
                  >
                    {imagePreview ? (
                      <div className="relative w-32  h-20  mt-2 mb-2">
                        <img
                          src={imagePreview}
                          alt="Nominee"
                          className="w-full h-20 mt-1 rounded-md"
                        />

                        <FontAwesomeIcon
                          icon={faTimes}
                          className="absolute bg-[#005555] rounded-full top-0 -right-2 text-white px-1 py-0.5 cursor-pointer"
                          onClick={removeImage}
                        />
                      </div>
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faUpload} className="mt-4 h-6" />
                        <p className="text-gray-400 mt-1">Upload Front Pic</p>
                        <input
                          type="file"
                          name="nomineePicture"
                          ref={fileInputRef}
                          className="hidden"
                          accept="image/*"
                          onChange={handleFileChange}
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className="w-full flex flex-col text-md mb-2 ml-2">
                  <div
                    className="w-full h-20 border-2 border-dashed border-gray-300 rounded-md flex flex-col items-center justify-center cursor-pointer"
                    onClick={() => {
                      if (imagePreviewBack) {
                      } else {
                        handleFileBClick();
                      }
                    }}
                  >
                    {imagePreviewBack ? (
                      <div className="relative w-32  h-20  mt-2 mb-2">
                        <img
                          src={imagePreviewBack}
                          alt="Nominee"
                          className="w-full h-20 mt-1 rounded-md"
                        />

                        <FontAwesomeIcon
                          icon={faTimes}
                          className="absolute bg-[#005555] rounded-full top-0 -right-2 text-white px-1 py-0.5 cursor-pointer"
                          onClick={removeBImage}
                        />
                      </div>
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faUpload} className="mt-4 h-6" />
                        <p className="text-gray-400 mt-1">Upload Back Pic</p>
                        <input
                          type="file"
                          name="nomineePicture"
                          ref={fileInputBackRef}
                          className="hidden"
                          accept="image/*"
                          onChange={handleABackChange}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
          <button
            type="submit"
            className="mt-4 cursor-pointer bg-[#005555] px-2 py-2 text-white rounded-md w-full"
          >
            {nomineeDetail.id ? "Update Nominee" : "Submit"}
          </button>
        </form>
      </AddNomineModal>
    </>
  );
};

export default NomineePage;
