import React, { useEffect, useState } from "react";
import toast from "toastr";
import { buyBricsApi } from "../../api";
import Loader from "../Loader";
import { useNavigate } from "react-router-dom";
const BuyBricsModal = ({
  getPropertyDetails,
  show,
  onClose,
  propertyDetails,
  checked,
  handleCheckboxChange,
}) => {
  toast.options = { preventDuplicates: true };

  const [numBrics, setNumBrics] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const handleNumBricsChange = (e) => {
    const value = e.target.value;
    setNumBrics(value);
  };

  useEffect(() => {
    if (numBrics) {
      setTotalPrice((numBrics * propertyDetails.eachBricCost || 0).toFixed(2));
    }
  }, [numBrics]);


  const handleBuy = async () => {
    try {
      if (numBrics == 0) {
        toast.error("Buy brics quantity should be greater than 0!");
        return;
      }
      if (numBrics > propertyDetails?.availableBrics) {
        toast.error(
          "Buy brics quantity should be less than or equal to available brics quantity!"
        );
        return;
      }
      if (!checked) {
        toast.error("Please accept our terms and conditions!");
        return;
      }

      setLoader(true);
      const obj = {
        amount: totalPrice,
        propertyId: propertyDetails?.id,
        brics: numBrics,
      };
      const res = await buyBricsApi(obj);
      if (res?.status == 200) {
        setLoader(false);
        toast.success(res?.data?.message);
        setTotalPrice(0);
        setNumBrics(0);
        onClose();
        getPropertyDetails();
      } else {
        toast.error(res?.data?.message);
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
    }
  };

  if (!show) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      {loader && <Loader />}
      <div className="bg-white rounded-lg p-6 w-full max-w-md relative">
        <button
          className="absolute top-2 right-2 font-bold text-[20px] text-gray-500 hover:text-gray-700"
          onClick={onClose}
        >
          &times;
        </button>
        <h2 className="text-xl font-bold mb-4 text-center">Buy Brics</h2>
        <div className="flex justify-between">
          <p className="flex flex-col w-full">
            <span className="font-bold">Total Brics:</span>{" "}
            <span className="w-full mt-1 bg-gray-200 border rounded-lg px-3 py-2">
              {propertyDetails?.totalBrics}{" "}
            </span>
          </p>
          <p className="flex flex-col  w-full ml-2">
            <span className="font-bold">Available Brics</span>{" "}
            <span className="w-full mt-1 bg-gray-200 border rounded-lg px-3 py-2">
              {propertyDetails?.availableBrics}
            </span>
          </p>
        </div>
        <p className=" flex flex-col">
          <span className="font-bold">Each Bric Cost</span>
          <span className="w-full mt-1 bg-gray-200 border rounded-lg px-3 py-2">
            ₹{propertyDetails?.eachBricCost}{" "}
          </span>
        </p>
        <div className="flex justify-between">
          <div className="flex flex-col w-full">
            <label
              className="block font-bold whitespace-nowrap mr-6 "
              htmlFor="numBrics"
            >
              Number of Brics
            </label>
            <input
              type="number"
              id="numBrics"
              value={numBrics}
              onChange={handleNumBricsChange}
              className="w-full mt-1 border rounded-lg px-3 py-2"
              min="1"
              max={propertyDetails?.availableBrics}
            />
          </div>
          <p className="flex flex-col w-full ml-2">
            <span className="font-bold">Total Cost:</span>
            <span className="w-full mt-1 bg-gray-200 border rounded-lg px-3 py-2">
            ₹{totalPrice}
            </span>
          </p>
        </div>
        <div className="flex">
          <input
            type="checkbox"
            checked={checked}
            onChange={handleCheckboxChange}
            className="-mt-4 mr-1"
          />
          <p className="flex">
            Please accept our{" "}
            <u
              className="ml-1 cursor-pointer text-blue-500"
              onClick={() => {
                navigate("/terms-and-conditions");
              }}
            >
              {" "}
              Terms & Conditions
            </u>
          </p>
        </div>
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="bg-gray-500 text-white px-4 py-2 rounded-lg mr-2"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={handleBuy}
            className="bg-green-500 text-white px-4 py-2 rounded-lg"
          >
            Buy
          </button>
        </div>
      </div>
    </div>
  );
};

export default BuyBricsModal;
