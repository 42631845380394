import React, { useEffect, useState } from "react";
import { getPurchasePropertyDetailApi, getBuyBricsApi, getSellBricsApi, getRentApi, withdrawBricsApi } from "../../api";
import ReactPaginate from "react-paginate";
import toast from "toastr";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import { formatDate, currancyFormat } from '../../utils/index';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PurchasedPage = () => {
  toast.options = { preventDuplicates: true };
  const params = new URLSearchParams(useLocation().search).get("id");
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("transaction");
  const [propertyDetails, setPropertyDetails] = useState({});
  const [purchaseTransactions, setpurchaseTransactions] = useState([]);
  const [sellTransactions, setSellTransactions] = useState([]);
  const [rentData, setRentData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [totalRentCount, setTotalRentCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [loader, setLoader] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [isBricModalOpen, setBricModalOpen] = useState(false);

  const settings = {
    dots: false,
    infinite: false,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  useEffect(() => {
    fetchProperties();
    loadBuyTransaction(1);
  }, []);

  
  const fetchProperties = async (pageNumber) => {
    try {
      setLoader(true);
      const response = await getPurchasePropertyDetailApi({ page: pageNumber });
      if (response?.status === 200) {
        setLoader(false);
        setPropertyDetails(response.data?.data[0] || {});
        setTotalCount(response.data?.count);
      } else {
        handleApiError(response);
      }
    } catch (error) {
      setLoader(false);
      console.error("Error fetching data:", error);
    }
  };


  const loadBuyTransaction = async (pageNumber) => {
    try {
      setLoader(true);
      const response = await getBuyBricsApi({ propertyId: params, page: pageNumber });
      if (response?.status === 200) {
        setLoader(false);
        setpurchaseTransactions(response.data?.data);
        setTotalCount(response.data?.count);

      } else if (response?.data?.error_description?.statusCode === 401) {
        toast.error(response?.data?.error_description?.message);
        setLoader(false);
        localStorage.clear();
        navigate("/login");
      } else {
        setLoader(false);
        console.error("Failed to fetch data:", response);
      }
    } catch (error) {
      setLoader(false);
      console.error("Error fetching data:", error);
    }
  };

  const loadSellTransaction = async (pageNumber) => {
    try {
      setLoader(true);
      const response = await getSellBricsApi({ propertyId: params, page: pageNumber });
      if (response?.status === 200) {
        setLoader(false);
        setSellTransactions(response.data?.data);
        setTotalCount(response.data?.count);

      } else if (response?.data?.error_description?.statusCode === 401) {
        toast.error(response?.data?.error_description?.message);
        setLoader(false);
        localStorage.clear();
        navigate("/login");
      } else {
        setLoader(false);
        console.error("Failed to fetch data:", response);
      }
    } catch (error) {
      setLoader(false);
      console.error("Error fetching data:", error);
    }
  };

  const fetchRentData = async (pageNumber) => {
    try {
      setLoader(true);
      const response = await getRentApi({ propertyId: params, page: pageNumber });
      if (response?.status === 200) {
        setLoader(false);
        setRentData(response.data?.data);
        setTotalRentCount(response.data?.count);
      } else if (response?.data?.error_description?.statusCode === 401) {
        toast.error(response?.data?.error_description?.message);
        setLoader(false);
        localStorage.clear();
        navigate("/login");
      } else {
        setLoader(false);
        console.error("Failed to fetch data:", response);
      }
    } catch (error) {
      setLoader(false);
      console.error("Error fetching data:", error);
    }
  };

  const handlePageClick = (selectedPage) => {
    const page = selectedPage.selected + 1;
    if (activeTab === "transaction") {
      loadBuyTransaction(page);
    } else if (activeTab === "sell") {
      loadSellTransaction(page);
    } else {
      fetchRentData(page);
    }
    setCurrentPage(page);
  };

  const handleApiError = (response) => {
    setLoader(false);
    if (response?.data?.error_description?.statusCode === 401) {
      toast.error(response?.data?.error_description?.message);
      localStorage.clear();
      navigate("/login");
    } else {
      console.error("Failed to fetch data:", response);
    }
  };


  const handleBricWithdraw = async () => {
    if (
      !dialogData.inputBrics ||
      Number(dialogData.inputBrics) == 0 ||
      Number(dialogData.inputBrics) > Number(dialogData.remainingbrics)
    ) {
      toast.error("Invalid withdraw amount.");
      return;
    }

    try {
      const payload = {
        propertyId: dialogData.id,
        brics: dialogData.inputBrics,
      };

      setLoader(true);
      const response = await withdrawBricsApi(payload);
      if (response?.status === 200) {
        toast.success(response.data.message);
        setBricModalOpen(false);
        fetchProperties(1);
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Withdrawal error:", error);
    }
  };


  const pageCount = activeTab === "transaction"
    ? Math.ceil(totalCount / itemsPerPage)
    : Math.ceil(totalRentCount / itemsPerPage);

  return (
    <div className="section-properties container">
      {/* Top Navigation Section */}
      <div className="w-full flex justify-between items-center">
        <p
          className="font-bold text-primary text-xl cursor-pointer my-4"
          onClick={() => navigate(-1)}
        >
          <FontAwesomeIcon icon={faArrowLeft} /> Transaction Details
        </p>
      </div>

      <div className="row mb-8">
        <div className="col-4">
          <a href={`/property-details?id=${propertyDetails?.id}`} className="img">
            <div className="features-1 rounded-lg p-6 mr-6">
              {propertyDetails.images && propertyDetails.images.length > 0 && (
                <Slider {...settings}>
                  {propertyDetails?.images.map((val1) => {
                    return (
                      <div className="relative">
                        {/* Image Section */}
                        <img
                          src={val1}
                          alt="Image"
                          className="h-[240px] object-cover w-full rounded-lg"
                        />
                      </div>
                    );
                  })}
                </Slider>

              )}

              <div className="text-start">
                <p className="property-title mt-4">
                  {propertyDetails.name}
                </p>
                <div className="row">
                  <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <span>Each Bric Cost</span>
                    <p className="text-primary font-bold">
                      {currancyFormat(propertyDetails.eachBricCost)}
                    </p>
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <span>Launch Date</span>
                    <p className="text-primary font-bold">
                      {formatDate(propertyDetails.launching_date)}
                    </p>
                  </div>

                  <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <span>Status</span>
                    <p className="status-badge">
                      {propertyDetails.available_brics === 0 ? (
                        <span className="badge bg-danger">Sold Out</span>
                      ) : new Date(propertyDetails.launching_date) > new Date() ? (
                        <span className="badge bg-warning">
                          Coming Soon
                        </span>
                      ) : (
                        <span className="badge bg-success">
                          Available
                        </span>
                      )}
                    </p>
                  </div>

                  <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <span>Available</span>
                    <p className="text-primary font-bold">
                      {propertyDetails.available_brics}
                    </p>
                  </div>

                  {propertyDetails.location && (
                    <p>
                      {propertyDetails.location.length > 50
                        ? propertyDetails.location.substring(0, 50) + "..."
                        : propertyDetails.location}
                    </p>
                  )}

                  <div className="property-status col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-4">
                    {/* Progress Bar Section */}
                    <div className="col-12">
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <span className="text-primary font-bold">
                          Brics Sold
                        </span>
                        <span className="text-sm font-medium text-gray-600">
                          {propertyDetails.total_brics - propertyDetails.available_brics}/
                          {propertyDetails.total_brics} Brics
                        </span>
                      </div>

                      <div className="w-full bg-gray-200 rounded-full h-3 relative">
                        <div
                          className="bg-green-500 h-3 rounded-full transition-all ease-out duration-300"
                          style={{
                            width: `${((propertyDetails.total_brics - propertyDetails.available_brics) /
                              propertyDetails.total_brics) *
                              100
                              }%`,
                          }}
                        />
                        <div className="absolute inset-0 flex justify-center items-center text-gray-500 font-bold">
                          {Math.round(
                            ((propertyDetails.total_brics - propertyDetails.available_brics) /
                              propertyDetails.total_brics) *
                            100
                          )}
                          %
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <a
                href={`/property-details?id=${propertyDetails?.id}`}
                className="learn-more"
              >
                See details
              </a>
            </div>
          </a>
        </div>
        <div className="col-8">
          {/* Detailed Information Section */}
          <div className="bg-white rounded-sm w-full">
            <div className="flex justify-between items-start">
              <h2 className="text-lg font-semibold text-gray-700 mb-4">
                Bric Details
              </h2>

              <button
                type="button"
                onClick={(e) => {
                  setDialogData({ ...propertyDetails, brics: "" });
                  setBricModalOpen(true);
                }}
                className="cursor-pointer border-2 bg-[#005555] outlined px-3 py-2 text-[#fff] text-sm rounded-md"
              >
                Withdraw Brics
              </button>
            </div>
            <div className="grid grid-cols-3 gap-2">
              {[
                {
                  label: "Total Purchased Brics",
                  value: propertyDetails?.totalpurchasedbrics,
                },
                {
                  label: "Withdraw In Prgress",
                  value: propertyDetails?.totalwithdrawprogress,
                },
                {
                  label: "Total Withdraw",
                  value: propertyDetails?.totalsoldbrics,
                },
                {
                  label: "Current QTY",
                  value: propertyDetails?.remainingbrics,
                },

              ].map((item, index) => (
                <div key={index}>
                  <span className="font-medium text-gray-700">
                    {item.label}
                  </span>
                  <p className="font-semibold text-[#005555]">
                    {item.value}
                  </p>
                </div>
              ))}

            </div>
          </div>

          {/* Tabs */}
          <div className="tabs my-4">
            <button
              className={`tab-button-solo ${activeTab === "transaction" ? "active-tab-border" : ""}`}
              onClick={() => setActiveTab("transaction")}
            >
              Buy Transactions
            </button>
            <button
              className={`tab-button-solo ${activeTab === "sell" ? "active-tab-border" : ""}`}
              onClick={() => {
                setActiveTab("sell");
                loadSellTransaction(1);
              }}
            >
              Sell Transactions
            </button>
            <button
              className={`tab-button-solo ${activeTab === "rent" ? "active-tab-border" : ""}`}
              onClick={() => {
                setActiveTab("rent");
                fetchRentData(1);
              }}
            >
              Rent Transactions
            </button>
          </div>

          {loader ? (
            <Loader />
          ) : (
            <div>
              {activeTab === "transaction" ? (
                // Transaction Tab Content
                purchaseTransactions && purchaseTransactions.length > 0 ? (
                  <table className="table table-striped table-hover">
                    <thead>
                      <tr>
                        <th>Property Name</th>
                        <th>Purchased Brics</th>
                        <th>Amount</th>
                        <th>Purchased Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {purchaseTransactions.map((val) => (
                        <tr key={val.id}>
                          <td>
                            <a href={`/property-details?id=${val?.property?.id}`}>
                              {val.property?.name}
                            </a>
                          </td>
                          <td>{val?.brics}</td>
                          <td>{currancyFormat(val?.amount)}</td>
                          <td>{formatDate(val?.createdAt)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="col-12 text-center">No transactions found.</div>
                )
              ) : activeTab === "sell" ? (
                // Rent Tab Content
                sellTransactions && sellTransactions.length > 0 ? (
                  <table className="table table-striped table-hover">
                    <thead>
                      <tr>
                        <th>Ref ID</th>
                        <th>Brics</th>
                        <th>Status</th>
                        <th>Reason</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sellTransactions.map((val) => (
                        <tr key={val.id}>
                          <td>{val.id}</td>
                          <td>{val.brics}</td>
                          <td>{val.status}</td>
                          <td>{val.reason || "-"}</td>
                          <td>{formatDate(val?.createdAt)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="col-12 text-center">No rent data found.</div>
                )
              ) : (
                // Rent Tab Content
                rentData && rentData.length > 0 ? (
                  <table className="table table-striped table-hover">
                    <thead>
                      <tr>
                        <th>Property Name</th>
                        <th>Rent Month</th>
                        <th>Rent Year</th>
                        <th>Amount</th>
                        <th>Rent Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rentData.map((val) => (
                        <tr key={val.id}>
                          <td>{val.property?.name}</td>
                          <td>{val.month}</td>
                          <td>{val.year}</td>
                          <td>{currancyFormat(val?.amount)}</td>
                          <td>{formatDate(val?.createdAt)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="col-12 text-center">No rent data found.</div>
                )
              )}

              <ReactPaginate
                breakLabel="..."
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                className={"pagination justify-content-center pt-4"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                activeClassName={"active"}
                onPageChange={handlePageClick}
                previousLabel={"Previous"}
                nextLabel={"Next"}
                previousClassName={"page-item"}
                nextClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextLinkClassName={"page-link"}
              />
            </div>
          )}
        </div>
      </div>
      {isBricModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          {loader && <div>Loading...</div>}
          <div className="bg-white rounded-lg p-6 w-full max-w-md relative">
            <button
              onClick={() => setBricModalOpen(false)}
              className="absolute top-2 right-2 font-bold text-[20px] text-gray-500 hover:text-gray-700"
            >
              &times;
            </button>
            <h2 className="text-xl font-bold mb-4 text-center">
              Withdraw Brics
            </h2>

            <p className="flex flex-col mb-4">
              <span className="font-bold">
                Available Brics: {dialogData.remainingbrics}
              </span>
              {/* <span className="w-full mt-1 bg-gray-200 border rounded-lg px-3 py-2">
              {totalBrics}
            </span> */}
            </p>

            <div className="flex flex-col mb-4">
              <label className="block font-bold mb-2" htmlFor="brics">
                Withdraw Brics
              </label>
              <input
                type="number"
                id="inputBrics"
                required
                value={dialogData.inputBrics}
                onChange={(e) =>
                  setDialogData({ ...dialogData, inputBrics: e.target.value })
                }
                className="w-full mt-1 border rounded-lg px-3 py-2"
                min="1"
                max={dialogData.remainingbrics}
              />
            </div>

            <div className="flex justify-end">
              <button
                disabled={loader}
                onClick={() => setBricModalOpen(false)}
                className="bg-gray-500 text-white px-4 py-2 rounded-lg mr-2"
              >
                Cancel
              </button>
              <button
                type="button"
                disabled={loader}
                onClick={handleBricWithdraw}
                className="bg-green-500 text-white px-4 py-2 rounded-lg"
              >
                {loader && (
                  <svg
                    aria-hidden="true"
                    role="status"
                    class="inline mr-3 w-4 h-4 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    ></path>
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                )}
                Withdraw
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PurchasedPage;
